import { Button, ControlType, Priority, Size, ProcessIndicator } from '@transferwise/components';
import { Cross as CloseIcon, Search as SearchIcon } from '@transferwise/icons';
import { debounce } from 'lodash';
import React, { useState } from 'react';

import { getSearchResults } from '../../helpers/search/getSearchResults';
import { useEffectWithoutInitialRender } from '../../hooks/useEffectWithoutInitialRender';
import { FuseResults } from '../../types/search';
import SearchResults from '../SearchResults/SearchResults';

import styles from './SearchArea.module.scss';

const SEARCH_DELAY = 900;
const delayedSearch = debounce(getSearchResults, SEARCH_DELAY, { leading: true });

export const SearchArea = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [keyword, setKeyword] = useState<string>('');
  const [searchResults, setSearchResults] = useState<Array<FuseResults> | null>(null);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setKeyword(query);
  };

  const clearSearch = () => {
    setKeyword('');
    setSearchResults(null);
    setIsLoading(false);
  };

  useEffectWithoutInitialRender(() => {
    if (keyword.length <= 2) {
      setSearchResults(null);
    } else {
      setIsLoading(true);
      delayedSearch(keyword)
        .then((res) => {
          setSearchResults(res.data);
          setIsLoading(false);
        })
        // eslint-disable-next-line no-console
        .catch((err) => console.error(err));
    }
  }, [keyword]);

  return (
    <div className={styles.container}>
      <h1>Search Wise Platform Documentation</h1>
      <div className="search-input-wrapper">
        <div className="input-group">
          <input
            className="search-input form-control"
            type="text"
            value={keyword}
            onChange={onChange}
            placeholder="Search..."
          />
          <div className="input-group-addon search-input-addon p-a-0">
            {keyword ? (
              <Button
                type={ControlType.ACCENT}
                priority={Priority.TERTIARY}
                size={Size.SMALL}
                className="p-x-1"
                onClick={clearSearch}
              >
                <CloseIcon size={24} />
              </Button>
            ) : (
              <div className="p-x-1">
                <SearchIcon size={24} />
              </div>
            )}
          </div>
        </div>
      </div>
      {isLoading && <ProcessIndicator size={Size.EXTRA_SMALL} className="m-a-auto m-y-5" />}
      {searchResults && !isLoading && <SearchResults results={searchResults} keyword={keyword} />}
    </div>
  );
};
