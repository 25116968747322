import { apiCall } from '@transferwise/api-call';

import { FuseResults } from '../../types/search';

const searchEndpoint = (query: string) =>
  `${process.env.NEXT_PUBLIC_LOCAL_API_URL || ''}/search-api?searchQuery=${query}`;

interface ApiResponse {
  data: Array<FuseResults>;
}
export const getSearchResults = (query: string) => {
  return apiCall(searchEndpoint(query)) as Promise<ApiResponse>;
};
